#root {
  min-width: 100vw;
  min-height: 100vh;
  //background-color: #fff;
  overflow: hidden;
}

.my-swal {
  z-index: 9000;
}

.swal2-container {
  z-index: 9000!important;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: rgb(73, 79, 82);
  color: white;
}

.nav-link {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.red-hover:hover {
  color: #ff372a;
}

.image-box {
  /* Here's the trick */
  background: linear-gradient(rgba(20,20,20,0.0), rgba(20,20,20,0.0)), url("../images/wallpaper.jpg") center center;

  /* Here's the same styles we applied to our content-div earlier */
  color: white;
  min-height: 100vh;
  /*display: flex;*/
  /*align-items: center;*/
  /*justify-content: center;*/
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
  height: 100%; /* fix react perfect scrollbar scrolling parent */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  src: local('Open Sans'), url(https://fonts.gstatic.com/s/opensans/v13/DXI1ORHCpsQm3Vp6mXoaTXhCUOGz7vYGh680lGh-uXM.woff) format('woff');
}
